<template>
  <div>
    <my-nav-bar
      title="退货审核"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div v-if="list && list.length > 0" class="tips">
        提示：往左滑动审核
      </div>
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        v-model="loading"
        :finished="finished"
        @load="loadData"
      >
        <van-swipe-cell v-for="(returnOrder, index) in list" :key="index">
          <van-cell :title="returnOrder.supplier.name" is-link :label="returnOrder.created_at" center @click="goToDetailView(returnOrder)">
            <span class="amount">￥{{ returnOrder.amount }}</span>
          </van-cell>
          <template #right>
            <van-button class="swipe-cell__btn" square type="primary" @click="handleOperate(returnOrder, 'pass')">通过</van-button>
            <van-button class="swipe-cell__btn" square type="danger" @click="handleOperate(returnOrder, 'refuse')">拒绝</van-button>
          </template>
        </van-swipe-cell>
      </van-list>
    </div>
    <my-dialog v-model="showDialog" title="审核" message="确定要通过审核吗？" @confirm="handleConfirm">
      <van-field v-if="operate === 'refuse'" v-model="reason" style="margin: 10px" label="原因" type="textarea" placeholder="请输入拒绝原因" />
    </my-dialog>
  </div>
</template>

<script>
import { getReturnOrders, checkReturnOrder } from '@/api/return_check'
import myDialog from '@/components/my-dialog.vue'
import MyNavBar from '../../components/my-nav-bar.vue'
export default {
  name: 'ReturnCheckIndex',
  components: { myDialog, MyNavBar },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      listQuery: {
        limit: 10,
        page: 0
      },
      showEmpty: false,
      showDialog: false,
      reason: '',
      operate: '',
      currentReturnOrder: null
    }
  },
  methods: {
    loadData() {
      this.listQuery.page++
      this.loading = true
      getReturnOrders(this.listQuery).then(res => {
        this.loading = false
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    },
    goToDetailView(returnOrder) {
      this.$router.push({
        path: '/return-check-detail',
        query: {
          id: returnOrder.id
        }
      })
    },
    handleOperate(returnOrder, operate) {
      this.operate = operate
      this.showDialog = true
      this.currentReturnOrder = returnOrder
    },
    handleConfirm() {
      const data = {
        id: this.currentReturnOrder.id,
        operate: this.operate,
        reason: this.reason
      }
      this.beginLoad()
      checkReturnOrder(data).then(res => {
        this.endLoad()
        this.reason = ''
        this.success(res.msg)
        this.listQuery.page = 0
        this.loading = false
        this.finished = false
        this.list = []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color';
  .tips {
    padding: 10px 15px;
    color: #969799;
  }
  .amount {
    color: $amountRedColor
  }
  .van-swipe-cell__right {
    .van-button {
      height: 100%;
    }
  }
</style>
