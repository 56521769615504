var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "退货审核",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.list && _vm.list.length > 0 ? _c('div', {
    staticClass: "tips"
  }, [_vm._v(" 提示：往左滑动审核 ")]) : _vm._e(), _vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.loadData
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (returnOrder, index) {
    return _c('van-swipe-cell', {
      key: index,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            staticClass: "swipe-cell__btn",
            attrs: {
              "square": "",
              "type": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOperate(returnOrder, 'pass');
              }
            }
          }, [_vm._v("通过")]), _c('van-button', {
            staticClass: "swipe-cell__btn",
            attrs: {
              "square": "",
              "type": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOperate(returnOrder, 'refuse');
              }
            }
          }, [_vm._v("拒绝")])];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "title": returnOrder.supplier.name,
        "is-link": "",
        "label": returnOrder.created_at,
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goToDetailView(returnOrder);
        }
      }
    }, [_c('span', {
      staticClass: "amount"
    }, [_vm._v("￥" + _vm._s(returnOrder.amount))])])], 1);
  }), 1)], 1), _c('my-dialog', {
    attrs: {
      "title": "审核",
      "message": "确定要通过审核吗？"
    },
    on: {
      "confirm": _vm.handleConfirm
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_vm.operate === 'refuse' ? _c('van-field', {
    staticStyle: {
      "margin": "10px"
    },
    attrs: {
      "label": "原因",
      "type": "textarea",
      "placeholder": "请输入拒绝原因"
    },
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }