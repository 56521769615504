import request from '@/utils/request'

// 获取退货单列表
export function getReturnOrders(params) {
  return request({
    method: 'get',
    url: 'return-check',
    params
  })
}

// 获取退货单详情
export function getReturnOrder(params) {
  return request({
    method: 'get',
    url: `return-check/${params.id}`
  })
}

// 审核退货单
export function checkReturnOrder(data) {
  return request({
    method: 'post',
    url: `return-check/${data.id}`,
    data
  })
}
