var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('van-dialog', {
    attrs: {
      "title": _vm.title,
      "message": _vm.message,
      "show-cancel-button": _vm.showCancelButton,
      "show-confirm-button": _vm.showConfirmButton,
      "confirm-button-text": "确定",
      "confirm-button-color": "#1989fa"
    },
    on: {
      "confirm": function confirm($event) {
        return _vm.$emit('confirm');
      },
      "close": function close($event) {
        return _vm.$emit('input', _vm.showDialog);
      }
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }