<template>
  <van-dialog
    v-model="showDialog"
    :title="title"
    :message="message"
    :show-cancel-button="showCancelButton"
    :show-confirm-button="showConfirmButton"
    confirm-button-text="确定"
    confirm-button-color="#1989fa"
    @confirm="$emit('confirm')"
    @close="$emit('input', showDialog)"
  >
    <slot />
  </van-dialog>
</template>

<script>
export default {
  name: 'MyDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  watch: {
    value(newVal) {
      this.showDialog = newVal
    }
  }
}
</script>

<style scoped>

</style>
